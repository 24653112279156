import { Link } from "gatsby";
import React from "react";
import BlogPostPreview from "./blog-post-preview";

function BlogPostPreviewList(props) {
  return (
    <>
      {props.title && <h2>{props.title}</h2>}
      {props.nodes && props.nodes.map(node => (
        <BlogPostPreview key={node.id} {...node} isInList />
      ))}
      {props.browseMoreHref && (
        <div>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </>
  );
}

BlogPostPreviewList.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogPostPreviewList;
