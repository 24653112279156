import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";

import styled from 'styled-components';
import Section from '../components/Section';
import Space from '../components/Space';
import OpeningHours from '../components/OpeningHours';
import Location from '../components/Location';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      location
      openingHours
    }
    route: sanityRoute {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    posts: allSanityPost(
      limit: 60
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
          }
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = data.route && !data.route.useSiteTitle && page.title;

  return (
    <Layout navMenuItems={menuItems}>
      <SEO
        title={site.title || pageTitle || "Missing title"}
        description={site.description || "Missing description"}
        keywords={site.keywords || []}
      />
      <Wrapper>
      <Section type={'slim'} layout={'row'} style={{justifyContent: 'space-between'}}>
        <Main>
          {postNodes && <BlogPostPreviewList nodes={postNodes} />}
        </Main>
        <Aside>
          <OpeningHours site={site} />

          <Space />

          <Location site={site} />
      </Aside>
      </Section>
    </Wrapper>
    </Layout>
  );
};

export default IndexPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: self-start;
  width: 100%;
  height: 100%;
  background: #f0f4f5;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1024px) {
    width: 70%;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: 1024px) {
    width: 30%;
    margin-left: 32px;
    margin-top: 0;
    position: sticky;
    top: 120px;
  }

  .CtaBlock {
    .CtaContent {
      max-width: 100%;

      h2 {
        font-size: 20px;

        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }

      .IconWrapper {
        padding: 0;

        .Icon {
          padding: 0;
          margin: -10px 0 4px -3px;
        }
      }
    }
  }
`;